
/**
 * Mixin that creates a new stacking context.
 * see https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
 */
@mixin md-stacking-context() {
  position: relative;

  // Use a transform to create a new stacking context.
  transform: translate3d(0, 0, 0);
}

/**
 * This mixin hides an element visually.
 * That means it's still accessible for screen-readers but not visible in view.
 */
@mixin md-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-transform: none;
  width: 1px;
}

/**
 * Forces an element to grow to fit floated contents; used as as an alternative to
 * `overflow: hidden;` because it doesn't cut off contents.
 */
@mixin md-clearfix {
  &::before, &::after {
    content: '';
    clear: both;
    display: table;
  }
}

/**
 * A mixin, which generates temporary ink ripple on a given component.
 * When $bindToParent is set to true, it will check for the focused class on the same selector as you included
 * that mixin.
 * It is also possible to specify the color palette of the temporary ripple. By default it uses the
 * accent palette for its background.
 */
@mixin md-temporary-ink-ripple($component, $bindToParent: false, $palette: $md-accent) {
  // TODO(mtlin): Replace when ink ripple component is implemented.
  // A placeholder ink ripple, shown when keyboard focused.
  .md-ink-ripple {
    border-radius: 50%;
    opacity: 0;
    height: 48px;
    left: 50%;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity ease 280ms, background-color ease 280ms;
    width: 48px;
  }

  // Fade in when radio focused.
  #{if($bindToParent, '&', '')}.md-#{$component}-focused .md-ink-ripple {
    opacity: 1;
    background-color: md-color($palette, 0.26);
  }

  // TODO(mtlin): This corresponds to disabled focus state, but it's unclear how to enter into
  // this state.
  #{if($bindToParent, '&', '')}.md-#{$component}-disabled .md-ink-ripple {
    background-color: #000;
  }
}
