@import 'theme-functions';


// Typography
$md-body-font-size-base: rem(1.4) !default;
$md-font-family: Roboto, 'Helvetica Neue', sans-serif !default;

// Media queries
$md-xsmall: 'max-width: 600px';

// TODO: Revisit all z-indices before beta
// z-index master list
$z-index-fab: 20 !default;
$z-index-drawer: 100 !default;
$z-index-overlay: 1000 !default;

// Global constants
$pi: 3.14159265;

// Padding between input toggles and their labels
$md-toggle-padding: 8px !default;
// Width and height of input toggles
$md-toggle-size: 20px !default;

// Easing Curves
// TODO(jelbourn): all of these need to be revisited

// The default animation curves used by material design.
$md-linear-out-slow-in-timing-function: cubic-bezier(0, 0, 0.2, 0.1) !default;
$md-fast-out-slow-in-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !default;
$md-fast-out-linear-in-timing-function: cubic-bezier(0.4, 0, 1, 1) !default;

$ease-in-out-curve-function: cubic-bezier(0.35, 0, 0.25, 1) !default;

$swift-ease-out-duration: 400ms !default;
$swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;
$swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function !default;

$swift-ease-in-duration: 300ms !default;
$swift-ease-in-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2) !default;
$swift-ease-in: all $swift-ease-in-duration $swift-ease-in-timing-function !default;

$swift-ease-in-out-duration: 500ms !default;
$swift-ease-in-out-timing-function: $ease-in-out-curve-function !default;
$swift-ease-in-out: all $swift-ease-in-out-duration $swift-ease-in-out-timing-function !default;

$swift-linear-duration: 80ms !default;
$swift-linear-timing-function: linear !default;
$swift-linear: all $swift-linear-duration $swift-linear-timing-function !default;
