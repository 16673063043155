// TODO(jelbourn): change from the `md` prefix to something else for everything in the toolkit.

@import 'variables';

/** The overlay-container is an invisible element which contains all individual overlays. */
.md-overlay-container {
  position: absolute;

  // Disable events from being captured on the overlay container.
  pointer-events: none;

  // The container should be the size of the viewport.
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/** A single overlay pane. */
.md-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: $z-index-overlay;
}
